import React from "react"
import Layout from "../components/layout"

const A11yPage = () => {
  return (
    <Layout>
      <h2>Building accessibility</h2>
      <p>
        Accessibility means the greatest number of users can perceive,
        understand, navigate, and interact with the content. I believe the web
        should be inclusive and usable for people with different abilities.
      </p>
      <p>
        I led the accessibility efforts within the ArcGIS Online and ArcGIS API
        for JavaScript teams at Esri. My responsibilities included:
      </p>
      <ul>
        <li>Building accessibility into the design process</li>
        <li>Disseminating knowledge to the product teams</li>
        <li>Testing feature implementations</li>
        <li>Providing development solutions</li>
      </ul>
      <p>
        I worked closely with a lot of similar-minded colleagues at Esri on the
        accessibility of Esri products. Our ultimate goal was to make map-based
        geospatial information accessible.
      </p>
      <h2>Presentations on accessibility</h2>
      <p>
        I have given a number of presentations on web accessibility in Esri
        GeoDev webinars and Esri Developer Summit.
      </p>

      <ul>
        <li>
          Accessible Web Mapping Apps, at 2019 Esri User Conference.{" "}
          <a href="https://jimmieego.github.io/2019-uc-a11y-slides/#/">
            View slides
          </a>
        </li>
        <li>
          Web Accessibility Best Practices, Esri GeoDev Webinar in January 2019.{" "}
          <a href="https://jimmieego.github.io/Web-Accessibility-Best-Practices/">
            View slides
          </a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://www.esri.com/en-us/landing-page/product/2018/geodev-webinar-series/web-accessibility-best-practices">
            View recording
          </a>
        </li>
        <li>
          Accessible Web Mapping Apps, at 2019 Esri DevSummit.{" "}
          <a href="https://arcg.is/1OPTCu">View slides</a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://youtu.be/McXvs3x2-6E">View recording</a>
        </li>
        <li>
          Improving Accessibility with ArcGIS Online Web Apps, at 2019 Esri
          DevSummit. <a href="https://arcg.is/08yqfD0">View slides</a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://youtu.be/HJlIdCs5Z54">View recording</a>
        </li>
        <li>
          DIY Accessibility, at 2018 Esri DevSummit.{" "}
          <a href="https://arcgis.github.io/interactive-design/a11y/files/DIY%20Accessibility_2018.pdf">
            View slides
          </a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://youtu.be/VNL4DHSS6RE">View recording</a>
        </li>
        <li>
          Accessible Web Mapping Apps, at 2018 Esri DevSummit.{" "}
          <a href="https://arcgis.github.io/interactive-design/a11y/files/Accessible%20Web%20Mapping%20Apps_2018.pdf">
            View slides
          </a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://youtu.be/mDb2goQ0mDM">View recording</a>
        </li>
        <li>
          Accessible Web Mapping Apps, at 2017 Esri DevSummit.{" "}
          <a href="https://arcgis.github.io/interactive-design/a11y/files/Accessible%20Web%20Mapping%20Apps_2017.pdf">
            View slides
          </a>{" "}
          <span role="img">&#183;</span>{" "}
          <a href="https://youtu.be/pSOMZjm_lco">View recording</a>
        </li>
      </ul>
    </Layout>
  )
}

export default A11yPage
